import { getAuthToken } from '@/auth/utils'
import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class ReportProvider extends HttpRequest {
  reportDownload () {
    this.setHeader(getAuthToken())
    return this.get('/report/report-download')
  }

  summaryByAgentPaginate (page = 1, limit = 10, query) {
    this.setHeader(getAuthToken())
    return this.get(`/report/summary-by-agent?page=${page}&limit=${limit}${generateQuery(query)}`)
  }

  summaryByChannelPaginate (page = 1, limit = 10, query) {
    this.setHeader(getAuthToken())
    return this.get(`/report/summary-by-channel?page=${page}&limit=${limit}${generateQuery(query)}`)
  }

  summaryByCaseType (page = 1, limit = 10, query) {
    this.setHeader(getAuthToken())
    return this.get(`/report/summary-by-case?page=${page}&limit=${limit}${generateQuery(query)}`)
  }

  ticketDetail (page = 1, limit = 10, query) {
    this.setHeader(getAuthToken())
    return this.get(`/report/ticket-detail?page=${page}&limit=${limit}${generateQuery(query)}`)
  }

  ticketDetailReport (query) {
    this.setHeader(getAuthToken())
    return this.get('/report/report-ticket-detail', query)
  }

  ticketDetailNewReport (query) {
    this.setHeader(getAuthToken())
    return this.get('/report/report-ticket-detail-new', query)
  }

  complainReport (query) {
    this.setHeader(getAuthToken())
    return this.get('/report/complain', query)
  }

  categoriesReport (query) {
    this.setHeader(getAuthToken())
    return this.get('/report/categories', query)
  }
}

export default ReportProvider
